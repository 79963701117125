import { render, staticRenderFns } from "./UIAlert.vue?vue&type=template&id=9188ef10&scoped=true"
import script from "./UIAlert.vue?vue&type=script&lang=js"
export * from "./UIAlert.vue?vue&type=script&lang=js"
import style0 from "./UIAlert.vue?vue&type=style&index=0&id=9188ef10&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9188ef10",
  null
  
)

export default component.exports