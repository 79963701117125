<template>
    <div v-if="visibleNotifications.length" class="service-notifications">
        <UIAccordion
            v-if="notificationList.notificationsWithDescription.length"
            :items="notificationList.notificationsWithDescription"
        >
            <template #header="{ item }">
                <UIIcon class="mr-2" :name="['fas', getIcon(item.type)]" />
                {{ item.header }}
            </template>
            <template #default="{ item }">
                {{ item.description }}
            </template>
        </UIAccordion>
        <template v-if="notificationList.notificationsWithoutDescription.length">
            <UIAlert
                v-for="notification in notificationList.notificationsWithoutDescription"
                :key="notification.id"
                :variant="notification.type"
            >
                {{ notification.header }}
            </UIAlert>
        </template>
    </div>
</template>

<script>
import { UIAccordion, UIAlert, UIIcon } from '@dundle/ui/components';

export default {
    components: {
        UIAccordion,
        UIIcon,
        UIAlert,
    },
    props: ['notifications', 'brand'],

    data() {
        return {
            icons: [
                { id: 'exclamation-triangle', type: 'warning' },
                { id: 'info-circle', type: 'info' },
                { id: 'exclamation-triangle', type: 'error' },
            ],
        };
    },

    computed: {
        visibleNotifications() {
            const routeParts = this.$route.name.split(';');
            let activeRoute = routeParts[routeParts.length - 1];
            const brand = this.$route.params.brand || this.brand;
            if (activeRoute === 'brand-product') activeRoute = 'brand';

            if (activeRoute === 'brand' || this.brand) {
                return this.notifications.filter(
                    notification => notification.tags?.includes(brand) && notification.tags?.includes(activeRoute)
                );
            } else {
                return this.notifications.filter(notification => notification.tags?.includes(activeRoute));
            }
        },

        notificationList() {
            return this.visibleNotifications.reduce(
                (result, notification) => {
                    if (notification.description !== ' ') {
                        result.notificationsWithDescription.push(notification);
                    } else {
                        result.notificationsWithoutDescription.push(notification);
                    }
                    return result;
                },
                { notificationsWithDescription: [], notificationsWithoutDescription: [] }
            );
        },
    },

    methods: {
        getIcon(type) {
            return this.icons.find(icon => icon.type === type).id;
        },
    },
};
</script>

<style lang="scss" scoped>
.service-notifications {
    width: 100%;

    .ui-accordion {
        ::v-deep .item {
            border-color: $color-warning-border;
            h3 {
                background: $color-warning-bg;

                button {
                    padding-left: 1rem;

                    .accordion-title,
                    svg {
                        color: $color-warning-text;
                    }

                    &:focus {
                        outline-color: $color-warning-text;
                    }

                    &:hover {
                        span,
                        svg {
                            color: $color-warning-hover;
                        }
                    }
                }
            }

            .content {
                background: $color-warning-bg;
                color: $color-warning-text;
            }
        }
    }
}
</style>
