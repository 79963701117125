<template>
    <div v-show="show" role="alert" class="ui-alert align-items-center" :class="variant">
        <div v-if="icon && iconType" class="d-flex">
            <UIIcon class="mr-2 mt-1" :name="['fas', iconType]" />
            <div class="alert-text"><slot /></div>
        </div>
        <slot v-else />
    </div>
</template>

<script>
import { UIIcon } from '@dundle/ui/components';

export default {
    components: {
        UIIcon,
    },

    props: {
        variant: {
            type: String,
            default: 'default',
        },

        show: {
            default: true,
        },

        icon: {
            default: false,
        },
    },

    data() {
        return {
            icons: [
                { id: 'exclamation-triangle', type: 'warning' },
                { id: 'info-circle', type: 'info' },
                { id: 'exclamation-triangle', type: 'error' },
            ],
        };
    },

    computed: {
        iconType() {
            const icon = this.icons.find(icon => icon.type === this.variant);
            return icon ? icon.id : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    &.warning {
        ::v-deep h1 {
            color: $color-warning-text;
        }

        color: $color-warning-text;
        background: $color-warning-bg;
        border-color: $color-warning-border;
    }

    &.success {
        ::v-deep h1 {
            color: $color-validation;
        }

        color: $color-validation;
        background: $color-validation-faded;
        border-color: $color-validation;
    }

    &.info {
        ::v-deep h1 {
            font-weight: bold;
            font-size: 1rem;
            color: black;
        }
        color: $color-text;
        background: rgba($color-primary, 0.1);
        border-color: rgba($color-primary, 0.15);
        margin-top: 1rem;

        @media screen and (min-width: 768px) {
            margin-top: 0;
            padding: 0 1.25rem;
        }
    }

    &.error {
        color: $color-error-text;
        background: rgba($color-error-text, 0.1);
        border-color: rgba($color-error-text, 0.15);
        margin-top: 1rem;
    }

    &.error-inline {
        color: $color-error-text;
    }
}
</style>
