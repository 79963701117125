<template>
    <div class="ui-accordion">
        <section v-for="(item, i) in items" :key="i" class="item" :class="{ active: value && value === item }">
            <h3 class="mb-0" @click="onClick(item, i)">
                <button
                    :id="`accordion-${i}`"
                    class="d-flex align-items-center justify-content-between m-0 w-100"
                    :item="item"
                    :aria-expanded="value && value === item ? true : 'false'"
                    :aria-controls="`section-${i}`"
                >
                    <span class="accordion-title font-weight-bold">
                        <slot name="header" :item="item" :active="value && value === item" />
                    </span>
                    <UIIcon
                        name="chevron-down"
                        class="accordion-icon"
                        :class="{ 'fa-rotate-180': value && value === item }"
                        :hydrate="true"
                    ></UIIcon>
                </button>
            </h3>
            <div
                :id="`section-${i}`"
                ref="content"
                class="content"
                role="region"
                :style="{ height: calculatedHeight(value, item) }"
                :aria-labelledby="`accordion-${i}`"
            >
                <div class="p-3">
                    <slot :item="item" />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { UIIcon } from '@dundle/ui/components';

export default {
    components: {
        UIIcon,
    },
    props: {
        selected: {
            default: () => ({}),
        },

        items: {
            type: Array,
        },
    },

    data() {
        return {
            value: this.selected,
            selectedHeight: 0,
        };
    },

    mounted() {
        const index = this.items.findIndex(item => item === this.selected);

        if (index) {
            const $content = this.$refs.content[index];

            if ($content) {
                this.selectedHeight = $content.scrollHeight;
            }
        }
    },

    methods: {
        onClick(item, index) {
            if (this.$refs.content) {
                const $content = this.$refs.content[index];

                if (this.value === item) {
                    this.value = null;
                } else {
                    this.value = item;

                    if ($content) {
                        this.selectedHeight = $content.scrollHeight;
                    }

                    this.$emit('click', { item, index });
                }
            }
        },
        calculatedHeight(value, item) {
            return value && value === item && this.selectedHeight ? this.selectedHeight + 'px' : undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-accordion {
    .item {
        border: 1px solid #d6dfe1;
        border-radius: $border-radius-default;
        margin-bottom: 0.75rem;
        & + .item > h3 {
            border-top: none;
        }

        & > h3 {
            cursor: pointer;
            font-size: 1rem;

            button {
                text-align: left;
                background: none;
                border: none;
                padding: 1rem;
                &:hover {
                    & > span,
                    svg {
                        color: $color-header-link-hover;
                    }
                }
            }
            button[aria-expanded='true'] {
                & > span,
                svg {
                    color: $color-header-link-hover;
                }
            }
        }

        .content {
            height: 0;
            overflow: hidden;
            background: $color-grey-1;
            transition: height 0.3s ease;
        }

        &.active .content {
            height: auto;
        }
    }
}
</style>
